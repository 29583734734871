@font-face {
  font-family: 'GTZirkon';
  src: local('GTZirkon'),
    url('../fonts/gt-zirkon/GT-Zirkon-Regular.woff2') format('woff');
}
@font-face {
  font-family: 'Goldenbook W01 Bold';
  src: url('../fonts/goldenbook/Goldenbook-W01-Bold.eot'); /* IE9*/
  src: url('../fonts/goldenbook/Goldenbook-W01-Bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/goldenbook/Goldenbook-W01-Bold.woff2')
      format('woff2'),
    /* chrome、firefox */ url('../fonts/goldenbook/Goldenbook-W01-Bold.woff')
      format('woff'),
    /* chrome、firefox */ url('../fonts/goldenbook/Goldenbook-W01-Bold.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('../fonts/goldenbook/Goldenbook-W01-Bold.svg#Goldenbook W01 Bold')
      format('svg'); /* iOS 4.1- */
}

* {
  font-family: 'GTZirkon', 'Goldenbook W01 Bold';
}

html {
  font-size: 17px;
  line-height: 1.35;
  scroll-behavior: smooth;
}
html,
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: black;
  min-height: 100%;
  height: 100%;
  background-color: #ffffff;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  height: 100%;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
img {
  max-width: 100%;
  height: auto;
  width: auto\9;
  border: none;
}
button {
  cursor: pointer;
  outline: none;
}

a {
  color: #2c2a2b;
  text-decoration: none;
}

[role='button'] {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-title {
  font-size: 36px;
  margin: 0;
  margin-bottom: 16px;
  color: #000;
}
.page-sub-title {
  font-size: 24px;
  margin: 0;
  color: #000;
}
.page-normal {
  font-size: 16px;
  margin: 0;
  color: #000;
}
