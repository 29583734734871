.nav-bar {
  width: 100%;
  align-items: flex-start;
  background-color: #eaecef;

  li {    
    font-size: 14px;
    height: 40px;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    text-transform: uppercase;
    position: relative;

    .link {      
      -moz-user-select: none;
      user-select: none;
      cursor: pointer;
    }
  }

  .sub-menu {
    background-clip: padding-box;
    background-color: #fff;
    border-top: 0px solid #fff;
    height: auto;
    left: 6px;
    padding: 10px;
    position: absolute;
    text-align: left;
    top: 40px;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    z-index: 100;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    -webkit-transition: 0.2s;
    transition: 0.3s;
    z-index: 99;
    width: 150px;
    border-radius: 5px;
    box-shadow: 0px 40px 50px rgba(44, 42, 43, 0.08);

    &.show {
      visibility: visible;
      opacity: 1.0;
    }

    li {
      list-style-type: none;
    }
  }
}
