.cart-form {
  .form-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 150%;
    text-transform: capitalize;
    color: #2c2a2b;
  }
  .results {
    &-header {
      &-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        /* or 229% */

        text-transform: capitalize;

        color: #2c2a2b;
      }

      .more {
        min-width: 32px;
      }
    }

    .line {
      border-bottom: 1px solid #cccccc;
      box-sizing: border-box;
      height: 1px;
    }

    &-empty {
      color: #b5b6c7;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;
      border-bottom: 1px solid #cccccc;
    }

    &-list {
      .row-item {
        border-bottom: 1px solid #cccccc;
        .product {
          color: #979797;
          .left {
            max-width: 72px;
            max-height: 72px;
            margin-right: 10px;
          }
          .right {
            .detail {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              /* or 143% */

              color: #2c2a2b;
            }
          }
        }
        .price {
          font-weight: 700;
          font-size: 14px;
          line-height: 150%;
          /* identical to box height, or 21px */

          display: flex;
          align-items: center;
          text-transform: uppercase;

          color: #2c2a2b;
        }
        .quantity {
          font-weight: 700;
          font-size: 14px;
          line-height: 150%;
          /* identical to box height, or 21px */

          display: flex;
          align-items: center;
          text-transform: uppercase;

          color: #2c2a2b;
        }
        .more {
          width: 30px;
          span {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            width: 28px;
            height: 28px;
            border-radius: 50%;
            border: 1px solid #cacdd8;
            box-sizing: border-box;

            color: #a2a6b0;

            &:first-child {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .order-total {
    margin-left: auto;
    margin-right: auto;
    max-width: 350px;
    background: #edeef2;
    padding-bottom: 40px;

    .order-header {
      padding: 20px;
      font-weight: 400;
      font-size: 21px;
      line-height: 32px;

      text-transform: uppercase;

      color: #2c2a2b;

      border: 1px solid #edeef2;
      box-sizing: border-box;

      span {
        font-size: 14px;
        text-transform: capitalize;
        color: #979797;
      }
    }

    .order-body {
      border: 1px solid #edeef2;
      box-sizing: border-box;
    }
    .order-content {
      padding-left: 20px;
      padding-right: 20px;

      > div {
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        /* identical to box height, or 229% */

        text-transform: capitalize;

        color: #000000;

        border-bottom: 1px solid #cccccc;
      }

      .total {
        font-weight: 700;
        padding-bottom: 25px;
        border-bottom: 0;
      }
    }
  }

  .btn-dark,
  .btn-normal,
  .btn-gray {
    width: 100%;
    height: 50px;

    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    margin-top: 5px;
    margin-bottom: 5px;
    border-width: 0;
  }

  .btn-gray {
    background: #f5f5f5;
    border: 1px solid #b5b6c7;
    box-sizing: border-box;

    span {
      color: #b5b6c7;
    }
  }

  .btn-normal {
    background: #fff;
    border: 1px solid #2c2a2b;
    box-sizing: border-box;

    span {
      color: #2c2a2b;
    }
  }

  .btn-dark {
    background-color: #2c2a2b;
    span {
      color: white;
      a {
        color: white;
      }
    }
  }
}
