.product-thumb {
  color: #2c2a2b;

  &-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      width: 100%;
      max-width: 250px;
      height: auto;
    }
  }
  
  &-price {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
  }
  &-detail {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }
}
