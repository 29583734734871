.ReactModal__Overlay {
  background-color: rgba(44, 42, 43, 0.15) !important;
}

.profile-form {
  width: 100%;

  .form-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    color: #2c2a2b;
  }

  .content {
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;

      text-transform: capitalize;
      color: #2c2a2b;

      margin-left: 10px;
      margin-top: 35px;
      margin-bottom: 10px;
    }

    .sub-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      display: flex;
      align-items: center;

      color: #2c2a2b;

      margin-top: 22px;
      margin-bottom: 20px;

      svg {
        margin-left: 4px;
        path {
          fill: #2c2a2b;
        }
        margin-right: 10px;
      }
    }

    .line {
      border-bottom: 1px solid #cccccc;
      box-sizing: border-box;
      height: 1px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 30px;

      svg {
        margin-left: 4px;
        path {
          fill: #2c2a2b;
        }
        margin-right: 10px;
      }

      .left {
        color: #979797;
        margin-right: 6px;
      }
      .value {
        margin-left: 30px;
        color: #2c2a2b;

        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #979797;
      }

      .first .value {
        margin-left: 0px;
      }
    }
  }

  .link {
    border: 0px;
    background-color: white;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;

    color: #1b76bd;
    cursor: pointer;

    margin-right: 14px;
  }
  .logout {
    color: #f00000;
  }
  
  #shipping {
    margin-top: 90px;
  }

  #payment {
    margin-top: 90px;
  }

  .btn-exit {
    width: 100%;
    height: 50px;
    background-color: #f5f5f5;
    border: 1px solid #e5e5e5;
    margin-top: 35px;
    margin-bottom: 15px;

    box-sizing: border-box;
    border-radius: 2px;

    span {
      font-size: 13px;
      line-height: 18px;
      font-weight: bold;
      letter-spacing: 0.1em;
      color: #b5b6c7;
    }
  }
}
