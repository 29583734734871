body {
  background-color: #edeef2;
}

.register-page {
  height: 100%;
  > div {
    height: 100%;
  }
}

.register-form {
  .register-form-container {
    max-width: 500px;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .register-buttons {
    max-width: 500px;
    margin-right: auto;
    .form-group {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .register-title {
    font-family: 'GoldenbookW01-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    /* identical to box height, or 111% */

    display: flex;
    align-items: center;

    color: #2c2a2b;
  }
  .form-group {
    max-width: 500px;
    margin-bottom: 20px;

    .input-field {
      color: #2c2a2b;
      .form-control {
        border: 1px solid #b5b6c7;
      }
    }

    .error-msg {
      color: #c02;
      font-size: 13px;
      height: 20px;
      padding-top: 4px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .input-field {
    color: #2c2a2b;
    margin: 5px 0 15px 0;
    .form-control {
      border: 1px solid #b5b6c7;
    }
  }

  .btn-prev,
  .btn-next {
    width: 100%;
    height: 50px;
    background: #f5f5f5;

    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    margin-top: 50px;
    margin-bottom: 80px;
    border-width: 0;
  }

  .disabled {
    background: #f5f5f5;
    span {
      color: #b5b6c7;
    }
  }

  .btn-prev,
  .btn-next {
    background-color: #2c2a2b;
    span {
      color: white;
    }
  }

  .btn-create {
    width: 100%;
    height: 50px;
    background-color: #2c2a2b;
    border: solid 1px black;
    box-sizing: border-box;
    border-radius: 2px;

    span {
      font-size: 14px;
      font-weight: bold;
      color: white;
    }
  }
}

.card-form, .payment-form {
  .register-buttons {
    max-width: 500px;
    
    .form-group {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .input-field {
    margin: 5px 0 15px 0;
    .form-control {
      font-family: sans-serif;
      font-size: 1em;
      max-width: 500px;
      padding: 13px 12px;
      color: #2c2a2b;
      border: 1px solid #b5b6c7;
    }
  }
  .StripeElement {
    height: 44px;
    display: block;
    margin: 5px 0 15px 0;
    max-width: 500px;
    padding: 13px 12px;
    outline: 0;
    border-radius: 2px;
    background: white;
    border: 1px solid #b5b6c7;
  }
}

.shipping-form {
  .form-group {
    max-width: 500px;
  }
  .form-group {
    .item-select {
      width: 100%;
      margin: 5px 0 15px 0;

      .select__control {
        height: 44px;
        border-radius: 2px;
        border: 1px solid #b5b6c7;
      }
    }
  }
}