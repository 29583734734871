.fields-list .select-filed {
  width: calc(100% - 35px);
}
.fields-list .form-group {
  margin-bottom: 1rem;
}
.select-filed .form-control {
  width: 100%;
  height: 44px;
  padding: 0.5rem 1rem;
  border: 2px solid #B5B6C7;
  outline: none;
}
.select-filed .field-error {
  border: 2px solid #993399;
}

.form-control::-moz-placeholder {
  color: #adadad;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #adadad;
}

.form-control::-webkit-input-placeholder {
  color: #adadad;
}
