.drop-file-input {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 160px;
  border: 1px dashed #edeef2;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f5f5f5;

  &.small {
    height: 46%;
  }

  &.empty {
    border: 2px dashed #c02;
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &:hover,
  &.dragover {
    opacity: 0.6;
  }

  &__label {
    text-align: center;
    color: #b5b6c7;
    font-weight: 600;
    padding: 10px;

    img {
      width: 30px;
    }

    p {
      max-width: 200px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      span {
        color: #00698c;
        margin-left: 2px;
        text-decoration: underline;
      }
    }
  }

  .drop-file-preview {
    &__item {
      position: relative;
      display: flex;
      margin-bottom: 10px;

      padding: 15px;
      border-radius: 20px;

      img {
        margin-right: 20px;
        max-height: 240px;
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &__del {
        background-color: #fffffff0;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: pointer;
        opacity: 0.2;
        transition: opacity 0.3s ease;
        font-size: 20px;
      }

      &:hover .drop-file-preview__item__del {
        opacity: 1;
      }
    }
  }
}
