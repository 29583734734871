.checkbox label {
  cursor: pointer;
}
.checkbox .label-txt {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
  font-weight: 400;
}
.checkbox input[type='checkbox'] {
  display: none;
}
.checkbox input[type='checkbox'] + span::after {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 0px;
  border: 2px solid #b5b6c7;
  -webkit-transition: 240ms;
  background: transparent;
  transition: 240ms;
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 4px;
}
.checkbox input[type='checkbox']:checked + span::after {
  border-color: #3486ae;
  font-family: 'FontAwesome';
}
.checkbox input[type='checkbox']:checked + span::before {
  content: '\f14a';
  font-family: 'FontAwesome';
  position: absolute;
  top: 0px;
  left: 6px;
  display: block;
  width: 16px;
  height: 16px;
  color: #3486ae;
  cursor: pointer;
}
