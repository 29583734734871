.checkout-form {
  .form-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 150%;
    text-transform: capitalize;
    color: #2c2a2b;
  }

  .info-form {
    .shop-detail {
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      /* identical to box height, or 229% */

      text-transform: capitalize;
      color: #2c2a2b;
      margin-bottom: 4px;
    }
    .line {
      border-bottom: 1px solid #cccccc;
      box-sizing: border-box;
      height: 1px;
    }

    .sub-title {
      margin-top: 20px;
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 60px;
      /* identical to box height, or 375% */

      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #2c2a2b;
    }

    .form-group {
      margin-bottom: 24px;
    }
  }

  .order-total {
    margin-left: auto;
    margin-right: auto;
    max-width: 350px;
    background: #edeef2;
    padding-bottom: 40px;

    .order-header {
      padding: 20px;
      font-weight: 400;
      font-size: 21px;
      line-height: 32px;

      text-transform: uppercase;

      color: #2c2a2b;

      border: 1px solid #edeef2;
      box-sizing: border-box;

      span {
        font-size: 14px;
        text-transform: capitalize;
        color: #979797;
      }
    }

    .order-body {
      border: 1px solid #edeef2;
      box-sizing: border-box;
      .total {
        margin-top: 15px;
        font-weight: 700;
        padding-bottom: 25px;
        
        font-size: 14px;
        line-height: 32px;
        /* identical to box height, or 229% */

        text-transform: capitalize;

        color: #000000;
      }
    }
    .order-content {
      padding-left: 10px;
      padding-right: 10px;

      > div {
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        /* identical to box height, or 229% */

        text-transform: capitalize;

        color: #000000;

        border-bottom: 1px solid #cccccc;
      }

      .order-item {
        border-bottom: 1px solid #cccccc;
        .image {
          width: 22%;
          color: #979797;
          .left {
            max-width: 48px;
            max-height: 48px;
            margin-right: 10px;
          }
        }
        .product {
          width: 78%;
          .detail {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;

            letter-spacing: -0.01em;

            color: #2c2a2b;
          }

          .other {
            font-size: 14px;
            line-height: 150%;
            /* identical to box height, or 21px */

            display: flex;
            align-items: center;
            text-transform: capitalize;

            .price {
              font-weight: 700;

              color: #2c2a2b;
              margin-right: 8px;
            }
            .quantity {
              font-weight: 400;

              color: #979797;
            }
          }
        }
      }

      .results-empty {
        color: #b5b6c7;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        border-bottom: 1px solid #cccccc;
      }
    }
  }

  .btn-dark {
    width: 100%;
    height: 50px;

    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    margin-top: 5px;
    margin-bottom: 5px;
    border-width: 0;
  }

  .btn-dark {
    background-color: #2c2a2b;
    span {
      color: white;
    }
  }
}
