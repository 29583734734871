.past-bids-form {
  .form-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    color: #2c2a2b;
  }

  .results-list {
    .row-item {
      display: flex;
      flex-wrap: wrap;
      background: #ffffff;
      border-top: 1px solid #edeef2;
      border-bottom: 1px solid #edeef2;
      box-sizing: border-box;
      font-weight: 400;

      -moz-user-select: none;
      user-select: none;

      .product {
        color: #979797;
        .left {
          max-width: 48px;
          max-height: 48px;
          margin-right: 10px;
        }
        .right {
          .detail {
            font-size: 13px;
            line-height: 18px;

            text-transform: capitalize;
            margin-bottom: 4px;
          }
          .status {
            svg {
              margin-top: -1px;
            }
            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              padding-left: 4px;
            }
          }
        }
      }

      .other {
        padding-left: 0;
        padding-right: 0;
        
        .price {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;

          &-title {
            line-height: 16px;
            color: #979797;
          }

          &-value {
            font-weight: 700;
            line-height: 150%;
            color: #2c2a2b;

            .paid {
              margin-left: 15px;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #1b76bd;
            }
          }

          &-bid {
            margin-top: 14px;
            line-height: 16px;
            color: #1b76bd;
            text-decoration-line: underline;
            cursor: pointer;
          }

          &-two {
            justify-content: space-between;
            .left,
            .right {
              width: 40%;
            }
            .middle {
              width: 15%;
            }

            .text-line {
              text-decoration-line: line-through;
            }

            .line {
              width: 2px;
              height: 100%;
              border-left: 1px solid #cacdd8;
            }
          }
        }

        .more {
          font-size: 14px;
          display: flex;
          justify-content: right;

          &-done,
          &-failed {
            width: 108px;
            height: 50px;

            background: #edeef2;
            border: 1px solid #edeef2;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &-done {
            color: #27ae60;
          }
          &-failed {
            color: #bd251b;
          }
        }
      }
    }
  }
}
