.header {
  background-size: 100% auto !important;
  &_user-name {
    margin-right: 8px;
  }
  .header-title {
    height: 74px;
  }
  &-logo {
    position: relative;
    margin-top: 30px;
    display: flex;

    .logo {
      display: flex;
      align-items: flex-end;
      color: white;
      text-decoration: none;
      padding-bottom: 10px;
      
    }
  }
  &-right {
    position: relative;
    margin-top: 30px;
    margin-right: 30px;
  }

  &-profile {
    margin-left: 20px;
    margin-right: 20px;
  }

  &-cart-count {
    position: absolute;
    right: -4px;
    top: -4px;
    width: 12px;
    height: 12px;

    text-align: center;
    font-size: 7px;
    line-height: 12px;

    border-radius: 6px;
    background: #1b76bd;
    color: #fff;
  }

  &-login {
    text-decoration: none;

    font-size: 14px;

    color: #2c2a2b;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 2140px) {
  .header {
    .logo {
      font-size: 48px;
    }
  }
}

@media screen and (max-width: 1920px) {
  .header {
    .container {
      max-width: 1440px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .header {
    min-height: 114px;

    .container {
      max-width: 1280px;
    }

    .logo {
      font-size: 36px;
      font-weight: 100;
    }
  }
}

@media screen and (max-width: 1280px) {
  .header {
    .logo {
      font-size: 24px;
      font-weight: 100;
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    .container {
      .page-logo {
        min-width: 360px;
      }
    }
  }
}
