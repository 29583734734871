.item-bids-form {
  .form-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    color: #2c2a2b;
  }

  .bid-product {
    color: #2c2a2b;
    background: rgba(237, 238, 242, 0.59);
      border: 1px solid #edeef2;
      box-sizing: border-box;
      padding: 10px 0;

    .left {
      display: flex;
      align-items: center;
      
      .icon {
        max-width: 48px;
        max-height: 48px;
        margin-right: 10px;
      }

      .detail {
        font-size: 13px;
        line-height: 18px;

        text-transform: capitalize;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .status {
        svg {
          margin-top: -1px;
        }
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          padding-left: 4px;
        }
      }
    }
  }

  .bids-title {
    position: relative;
    &-left {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;

      text-transform: capitalize;
      color: #2c2a2b;

      span {
        margin-left: 4px;
        font-weight: 400;
        line-height: 16px;
        color: #b5b6c7;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 13px;
      line-height: 210%;

      color: #a2a6b0;

      span {
        margin-left: 4px;
        font-weight: 600;
        color: #2c2a2b;
      }
    }
  }
  .bids-list {
    .row-item {
      display: flex;
      flex-wrap: wrap;
      background: #ffffff;      
      margin-bottom: 14px;
      font-weight: 400;

      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
      color: #2c2a2b;

      .bid {
        .email {
          color: #979797;
        }
      }
      .other {
        .phone,
        .date {
          color: #979797;
        }
        .price {
          font-weight: 500;
          color: #000000;
        }
        .more {
          text-align: right;
          min-width: 28px;

          cursor: pointer;
        }
      }
    }
  }
}
