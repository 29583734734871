.ReactModal__Overlay {
  background-color: rgba(44, 42, 43, 0.15) !important;
}

.increase-bid-modal {
  max-width: 500px;
  width: 100%;

  position: absolute;
  inset: 50% auto auto 49%;

  background: #ffffff;
  box-shadow: 0px 40px 50px rgba(44, 42, 43, 0.07);

  border: 1px solid #f5f5f5;

  border-radius: 0px;
  outline: none;
  padding: 20px;
  transform: translate(-50%, -50%);

  .form-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    color: #2c2a2b;
  }

  .content {
    .bid-item {
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #2c2a2b;
      background: #edeef2;

      padding: 16px;
      margin-top: 35px;
      margin-bottom: 15px;

      .left {
        max-width: 64px;
        max-height: 64px;
        margin-right: 15px;
      }
      .right {
        letter-spacing: -0.01em;
        opacity: 0.7;
        color: #2c2a2b;

        .name {
          font-weight: 700;
          margin-bottom: 8px;
        }
        .detail {
          font-weight: 400;
        }
      }
    }

    .bid-amount {
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      padding: 16px 12px;
      border: 1px solid #edeef2;
      box-sizing: border-box;
      margin-bottom: 40px;

      .left {
        width: 50%;
        .view-bid {
          width: 40%;
          line-height: 14px;
          text-decoration-line: underline;
          color: #1b76bd;
          cursor: pointer;
        }
      }
      .right {
        width: 50%;
        .remain {
          width: 45%;
        }
      }
      .first {
        width: 60%;
        .amount {
          color: #979797;
        }
        .value {
          margin-top: 2px;
          font-weight: 500;
          color: #001b2e;
        }
      }
    }

    .increase-bid {
      margin-bottom: 12px;
      .bid-title {
        font-weight: 500;
        font-size: 14px;
        color: #2c2a2b;
        margin-bottom: 4px;
      }
      .bid-input {
        background: #fcfcfd;
        border: 1px solid #edeef2;
        box-sizing: border-box;
        padding: 8px 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 210%;
        color: #000000;
      }
    }

    .custom-bid {
      font-size: 13px;

      .custom-item {
        min-width: 42px;
        height: 24px;
        padding: 6px;
        margin-right: 12px;
        background: #edeef2;
        border: 1px solid #edeef2;
        box-sizing: border-box;
        border-radius: 2px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #2c2a2b;
        cursor: pointer;

        &.selected {
          border: 1px solid #2c2a2b;
        }
      }
      .custom-other {
        height: 24px;

        background: #2c2a2b;
        border: 1px solid #2c2a2b;
        box-sizing: border-box;
        border-radius: 2px;
        display: flex;
        align-items: center;
        text-transform: lowercase;
        padding: 6px;

        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .btn-place {
    width: 60%;
    height: 50px;
    margin-right: 10px;
    background-color: #2c2a2b;
    border: 1px solid #2c2a2b;
    margin-top: 35px;
    margin-bottom: 15px;

    box-sizing: border-box;
    border-radius: 2px;

    span {
      font-size: 13px;
      line-height: 18px;
      font-weight: bold;
      letter-spacing: 0.1em;
      color: #fff;
      text-transform: uppercase;
    }
  }
  .btn-exit {
    width: 40%;
    height: 50px;
    background-color: #f5f5f5;
    border: 1px solid #e5e5e5;
    margin-top: 35px;
    margin-bottom: 15px;

    box-sizing: border-box;
    border-radius: 2px;

    span {
      font-size: 13px;
      line-height: 18px;
      font-weight: bold;
      letter-spacing: 0.1em;
      color: #b5b6c7;
      text-transform: uppercase;
    }
  }
}
