.product-list-form {
  margin-top: 40px;
  margin-bottom: 20px;

  .product-list-search {
    li {
      list-style: none;
      color: #b5b6c7;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .product-list-filter {
    .custom-icon {
      position: absolute;
      z-index: 0;
      display: block;
      padding-left: 0.3rem;
      width: 2rem;
      line-height: 48px;
      text-align: center;
      pointer-events: none;
      color: #888;
      font-size: 0.8rem;
    }

    .filter-form {
      .form-group {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .search-input {
        border: 1px solid #cccccc;
        width: 100%;
        height: 48px;
        padding: 8px 16px;
        padding-left: 2rem;
      }

      .price-group {
        margin-top: 16px;
        padding: 10px 20px;
        background: #ffffff;
        border: 1px solid #edeef2;
        box-sizing: border-box;

        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #2c2a2b;
      }

      .product-filter {
        user-select: none;
        -moz-user-select: none;
        margin-left: 10px;
        margin-top: 10px;
        .checkbox .label-txt {
          font-weight: 400;
          font-size: 16px;
          color: #2c2a2b;
        }
      }
    }

    .price-input-group {
      max-width: 320px;
      .hypen {
        color: #c4c4c4;
        line-height: 44px;
      }

      .price-input {
        padding-left: 1.8rem;

        border: 1px solid #cccccc;
        width: 100%;
        height: 44px;
        padding-left: 2rem;
      }
    }

    .nav-item {
      user-select: none;
      -moz-user-select: none;
      cursor: pointer;

      background: #edeef2;
      border: 1px solid #edeef2;
      box-sizing: border-box;
      height: 54px;
      color: #2c2a2b;
      padding: 16px 20px;

      &.selected {
        background: #1b76bd;
        color: #ffffff;
      }
    }
  }

  .product-list-results {
    .results-count {
      color: #2c2a2b;
      font-size: 13px;
      line-height: 30px;
    }

    .results-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: 30px;

      .results-item {
        padding: 1.5%;
        cursor: pointer;
      }
      .results-empty {
        color: #b5b6c7;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
      }
    }
    .view-more {
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      button {
        padding: 16px 20px;

        width: 200px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #2c2a2b;
        box-sizing: border-box;
        text-transform: uppercase;

        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.1em;
      }
    }
  }
}
