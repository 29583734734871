.viewmore-form {
  margin-top: 40px;
  margin-bottom: 20px;

  .viewmore-header {
    width: 100%;
    height: 66px;
    color: #2c2a2b;
    text-transform: uppercase;
  }

  .viewmore-left {
    padding-left: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 60px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
  }
  .viewmore-right {
    padding-right: 20px;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;

    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #2c2a2b;

    i {
      margin-left: 6px;
    }
  }

  .viewmore-results {
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: 30px;

      .results-item {
        padding: 1.5%;
      }
    }
    &-load-more {
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      button {
        padding: 16px 20px;

        width: 200px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #2c2a2b;
        box-sizing: border-box;
        text-transform: uppercase;

        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.1em;
      }
    }
  }
}
