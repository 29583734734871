.portal-menu {
  width: 100%;
  align-items: flex-start;
  background-color: #eaecef;

  flex-direction: column;

  div {
    width: 100%;

    &:last-child {
      .line {
        height: 0px;
        border: 0px;
        padding-bottom: 20px;
      }
    }
  }

  .portal-type {
    margin-top: 20px;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    color: #979797;

    padding-top: 10px;
    padding-left: 10px;
  }

  li {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 16px;

    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    text-transform: capitalize;
    color: #2c2a2b;

    &.selected {
      .bar {
        position: absolute;
        left: 0;
        width: 2px;
        height: 16px;
        background: #1B76BD;
      }
  
      a {
        font-weight: 600;
      }
    }
  }

  .line {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    height: 6px;
    border-bottom: 1px solid #cacdd8;
    box-sizing: border-box;
  }
}
