.addnew-form {
  .form-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    color: #2c2a2b;
  }
  .line {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    height: 1px;
    border: 1px solid #cacdd8;
    box-sizing: border-box;
  }

  .input-title {
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #979797;
    }
  }
  .form-group {
    margin-bottom: 0;

    .input-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 13px;
      color: #979797;
      margin-bottom: 8px;
      margin-top: 20px;
    }
    .input-name {
      font-size: 16px;
    }
    .input-field {
      color: #2c2a2b;
      .form-control {
        border-width: 1px;

        &:hover,
        &:focus {
          box-shadow: none !important;
          border-color: #888 !important;
        }
      }
    }

    .item-select {
      .select__control {
        font-size: 16px;
        box-shadow: none !important;
        border-color: #b5b6c7;

        height: 44px;
        border-radius: 2px;

        &:hover,
        &:focus {
          border-color: #888 !important;
        }
      }
    }

    .error-msg {
      color: #c02;
      font-size: 13px;
      height: 20px;
    }
  }

  .file-input-group {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;

    .drop-file-input {
      margin-bottom: 4%;
    }
    .remain-group {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .drop-file-input {
        margin-bottom: 2%;

        @media screen and (min-width: 767px) {
          margin-bottom: 6%;
        }
      }
    }
  }

  .btn-add,
  .btn-preview {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;

    span {
      font-size: 13px;
      font-weight: bold;
    }
  }

  .btn-add {
    background-color: #2c2a2b;
    border: solid 1px black;

    span {
      color: white;
    }
  }

  .btn-preview {
    background-color: white;
    border: solid 1px #2c2a2b;

    span {
      color: #2c2a2b;
    }
  }
}
