.fields-list .input-field {
  width: calc(100% - 35px);
}
.fields-list .form-group {
  margin-bottom: 1rem;
}
.input-field .form-control {
  width: 100%;
  height: 44px;
  padding: 0.5rem 0.5rem;
  border: 2px solid #B5B6C7;
  border-radius: 2px;
  font-size: 14px;
  outline: none;
  resize: vertical;
}
.input-field .field-error {
  border: 2px solid #993399;
}

.form-control::-moz-placeholder {
  color: black;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: black;
}

.form-control::-webkit-input-placeholder {
  color: black;
}
