.footer {
  background: #edeef2;

  .footer-items {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 25px;
    /* or 179% */

    color: #2c2a2b;

    &-item {
      
      &:first-child {
        font-weight: 700;
      }
    }
  }

  .footer-company {
    font-size: 14px;
    line-height: 25px;

    text-align: right;
    color: #2c2a2b;
  }
}
