.ReactModal__Overlay {
  background-color: rgba(44, 42, 43, 0.15) !important;
}

.card-edit-modal {
  max-width: 500px;
  width: 100%;

  position: absolute;
  inset: 50% auto auto 49%;

  background: #ffffff;
  box-shadow: 0px 40px 50px rgba(44, 42, 43, 0.07);

  border: 1px solid #f5f5f5;

  border-radius: 0px;
  outline: none;
  padding: 20px;
  transform: translate(-50%, -50%);

  .form-title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    color: #2c2a2b;
  }

  .card-form {
    .register-buttons {
    }

    .btn-prev,
    .btn-next {
      width: 100%;
      height: 50px;

      margin-top: 35px;
      margin-bottom: 15px;

      box-sizing: border-box;
      border-radius: 2px;

      span {
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        /* identical to box height, or 138% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }

    .btn-next,
    .btn-prev {
      background: #2c2a2b;
      border: 1px solid #2c2a2b;
      margin-right: 10px;

      span {
        color: #ffffff;
      }
    }
    .disabled {
      background: #f5f5f5;
      border: 1px solid #f5f5f5;

      span {
        color: #b5b6c7;
      }
    }
  }
}
