.img-responsive {
  max-width: 100%;
  height: auto;
  width: auto\9;
  border: none;
}
.container {
  max-width: 1396px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
.inner-page-container {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.page-panel-group-container {
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.inner-page-container .page-panel-group-container,
.container .page-panel-group-container {
  padding-left: 0;
  padding-right: 0;
}
.row-mx-15 {
  margin-left: -15px;
  margin-right: -15px;
}
.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-reverse {
  flex-direction: row-reverse;
}
.flex-grow-1 {
  flex-grow: 1;
}

.flex-jc {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}
.items-bottom {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.self-center {
  align-self: center;
}
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
  width: 100%;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.text-transparent {
  color: transparent;
}
.text-black {
  color: #000000;
}
.text-white {
  color: #ffffff;
}
.text-orange {
  color: #d0561f;
}
.text-blue {
  color: #2aacc9;
}
.text-dark-blue {
  color: #00698c !important;
}
.text-dark {
  color: #2C2A2B;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 900;
}

.text-xs {
  font-size: 0.5rem;
}
.text-sm {
  font-size: 0.75rem;
}
.text-sm-base {
  font-size: 0.9rem;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.15rem;
}
.text-xl {
  font-size: 1.3rem;
}
.text-xl2 {
  font-size: 1.5rem;
}
.text-2xl {
  font-size: 1.7rem;
}
.font-24 {
  font-size: 24px;
}
.uppercase {
  text-transform: uppercase;
}
.underline {
  text-decoration: underline !important;
}
.no-underline {
  text-decoration: none !important;
}
.list-reset {
  list-style: none;
  padding: 0;
}
.inline-list li {
  display: inline-block;
}
.bg-transparent {
  background: transparent !important;
}
.bg-light-grey {
  background: #e5e5e5;
}
.bg-white {
  background: #fff;
}
.bg-black {
  background: #000;
}
.rounded-0 {
  border-radius: 0;
}
.rounded-2 {
  border-radius: 2px;
}
.rounded-3 {
  border-radius: 3px;
}
.rounded-4 {
  border-radius: 4px;
}
.rounded-5 {
  border-radius: 5px;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-dotted {
  border-style: dotted;
}
.border-none {
  border-style: none;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0;
}
.border-2 {
  border-width: 2px;
}
.border-3 {
  border-width: 3px;
}
.border-4 {
  border-width: 4px;
}
.border-5 {
  border-width: 5px;
}
.border-white {
  border-color: #fff;
}

.w-full,
.w-1-6,
.w-1-5,
.w-1-4,
.w-1-3,
.w-1-2,
.w-2-5,
.w-3-5,
.w-3-4,
.w-4-5,
.w-5-6,
.sm-w-1-5,
.sm-w-1-4,
.sm-w-1-3,
.sm-w-2-5,
.sm-w-1-2,
.lg-w-1-5,
.lg-w-1-4,
.lg-w-1-3,
.lg-w-2-5,
.lg-w-1-2,
.md-w-1-5,
.md-w-1-4,
.md-w-1-3,
.md-w-2-5,
.md-w-1-2 {
  padding-left: 15px;
  padding-right: 15px;
}
.w-auto {
  width: 100%;
}
.w-1-6 {
  width: 16.66667%;
}
.w-1-5 {
  width: 20%;
}
.w-1-4 {
  width: 25%;
}
.w-1-3 {
  width: 33.33333%;
}
.w-2-5 {
  width: 40%;
}
.w-1-2 {
  width: 50%;
}
.w-3-5 {
  width: 60%;
}
.w-3-4 {
  width: 75%;
}
.w-4-5 {
  width: 80%;
}
.w-5-6 {
  width: 83.33333%;
}
.w-full {
  width: 100%;
}
.w-min-1-6 {
  min-width: 16.66667%;
}
.w-min-1-5 {
  min-width: 20%;
}
.w-min-1-4 {
  min-width: 25%;
}
.w-min-1-3 {
  min-width: 33.33333%;
}
.w-min-2-5 {
  min-width: 40%;
}
.w-min-1-2 {
  min-width: 50%;
}
.w-min-3-5 {
  min-width: 60%;
}
.w-min-3-4 {
  min-width: 75%;
}
.w-min-4-5 {
  min-width: 80%;
}
.w-min-5-6 {
  min-width: 83.33333%;
}
.w-min-full {
  min-width: 100%;
}
.w-min-80 {
  min-width: 80px;
}
.w-min-90 {
  min-width: 90px;
}
.w-min-100 {
  min-width: 100px;
}
.w-min-110 {
  min-width: 110px;
}
.w-min-120 {
  min-width: 120px;
}

.w-80 {
  width: 80px;
}
.w-90 {
  width: 90px;
}
.w-100 {
  width: 100px;
}
.w-110 {
  width: 110px;
}
.w-120 {
  width: 120px;
}
.m-0 {
  margin: 0;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-70 {
  margin-top: 70px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.ml-auto {
  margin-left: auto;
}
.ml-0 {
  margin-left: 0;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-auto {
  margin-right: auto;
}
.mr-0 {
  margin-right: 0;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-40 {
  margin-right: 40px;
}
.p-30 {
  padding: 30px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.d-flex {
  display: flex;
  align-items: baseline;
}

.fas,
i {
  color: inherit;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.pl-0 {
  padding-left: 0px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-0 {
  padding-right: 0px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-30 {
  padding-right: 30px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.cursor-pointer {
  cursor: pointer;
}
@media (min-width: 767px) {
  .sm-flex-reverse {
    flex-direction: row-reverse;
  }
  .sm-items-center {
    align-items: center;
  }
  .sm-justify-center {
    justify-content: center;
  }
  .sm-justify-between {
    justify-content: space-between;
  }
  .sm-justify-around {
    justify-content: space-around;
  }
  .sm-justify-evenly {
    justify-content: space-evenly;
  }
  .sm-justify-start {
    justify-content: flex-start;
  }
  .sm-justify-end {
    justify-content: flex-end;
  }
  .sm-w-auto {
    width: auto;
  }
  .sm-w-1-5 {
    width: 20%;
  }
  .sm-w-1-4 {
    width: 25%;
  }
  .sm-w-1-3 {
    width: 33.33333%;
  }
  .sm-w-3-8 {
    width: 36.5%;
    padding: 0 15px;
  }
  .sm-w-1-2 {
    width: 50%;
  }
  .sm-w-2-5 {
    width: 40%;
  }
  .sm-w-3-5 {
    width: 60%;
  }
  .sm-w-5-8 {
    width: 62.5%;
    padding: 0 15px;
  }
  .sm-w-2-3 {
    width: 66.66666%;
  }
  .sm-w-3-4 {
    width: 75%;
  }
  .sm-w-4-5 {
    width: 80%;
  }
  .sm-mt-0 {
    margin-top: 0 !important;
  }
  .sm-text-xs {
    font-size: 0.5rem;
  }
  .sm-text-sm {
    font-size: 0.75rem;
  }
  .sm-text-base {
    font-size: 1rem;
  }
  .sm-text-lg {
    font-size: 1.2rem;
  }
  .sm-text-xl {
    font-size: 1.4rem;
  }
  .sm-text-xl2 {
    font-size: 1.6rem;
  }
  .sm-text-2xl {
    font-size: 1.8rem;
  }
  .sm-m-0 {
    margin: 0;
  }
  .sm-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm-mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .sm-mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .sm-mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .sm-mx-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .sm-my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .sm-my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .sm-my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .sm-my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .sm-my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .sm-mt-0 {
    margin-top: 0 !important;
  }
  .sm-mt-10 {
    margin-top: 10px;
  }
  .sm-mt-15 {
    margin-top: 15px;
  }
  .sm-mt-20 {
    margin-top: 20px;
  }
  .sm-mt-30 {
    margin-top: 30px;
  }
  .sm-mt-40 {
    margin-top: 40px;
  }
  .sm-mt-50 {
    margin-top: 50px;
  }
  .sm-mt-70 {
    margin-top: 70px;
  }
  .sm-mb-0 {
    margin-bottom: 0;
  }
  .sm-mb-10 {
    margin-bottom: 10px;
  }
  .sm-mb-15 {
    margin-bottom: 15px;
  }
  .sm-mb-20 {
    margin-bottom: 20px;
  }
  .sm-mb-30 {
    margin-bottom: 30px;
  }
  .sm-mb-40 {
    margin-bottom: 40px;
  }
  .sm-mb-50 {
    margin-bottom: 50px;
  }
  .sm-ml-auto {
    margin-left: auto;
  }
  .sm-ml-0 {
    margin-left: 0;
  }
  .sm-ml-10 {
    margin-left: 10px;
  }
  .sm-ml-15 {
    margin-left: 15px;
  }
  .sm-ml-20 {
    margin-left: 20px;
  }
  .sm-ml-30 {
    margin-left: 30px;
  }
  .sm-ml-40 {
    margin-left: 40px;
  }
  .sm-mr-auto {
    margin-right: auto;
  }
  .sm-mr-0 {
    margin-right: 0;
  }
  .sm-mr-10 {
    margin-right: 10px;
  }
  .sm-mr-15 {
    margin-right: 15px;
  }
  .sm-mr-20 {
    margin-right: 20px;
  }
  .sm-mr-30 {
    margin-right: 30px;
  }
  .sm-mr-40 {
    margin-right: 40px;
  }
  .sm-p-30 {
    padding: 30px;
  }
  .sm-py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sm-py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .sm-py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .sm-py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .sm-py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .sm-py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .sm-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .sm-px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .sm-px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sm-px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sm-px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sm-px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .sm-px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sm-pl-0 {
    padding-left: 0px;
  }
  .sm-pl-10 {
    padding-left: 10px;
  }
  .sm-pl-15 {
    padding-left: 15px;
  }
  .sm-pl-20 {
    padding-left: 20px;
  }
  .sm-pl-30 {
    padding-left: 30px;
  }
  .sm-pr-0 {
    padding-right: 0px;
  }
  .sm-pr-10 {
    padding-right: 10px;
  }
  .sm-pr-15 {
    padding-right: 15px;
  }
  .sm-pr-20 {
    padding-right: 20px;
  }
  .sm-pr-30 {
    padding-right: 30px;
  }
  .sm-pt-10 {
    padding-top: 10px;
  }
  .sm-pt-20 {
    padding-top: 20px;
  }
  .sm-pt-30 {
    padding-top: 30px;
  }
  .sm-pt-40 {
    padding-top: 40px;
  }
  .sm-pt-50 {
    padding-top: 50px;
  }
  .sm-pb-0 {
    padding-bottom: 0;
  }
  .sm-pb-10 {
    padding-bottom: 10px;
  }
  .sm-pb-20 {
    padding-bottom: 20px;
  }
  .sm-pb-30 {
    padding-bottom: 30px;
  }
  .sm-pb-40 {
    padding-bottom: 40px;
  }
  .sm-pb-50 {
    padding-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .lg-flex-reverse {
    flex-direction: row-reverse;
  }
  .lg-items-center {
    align-items: center;
  }
  .lg-justify-center {
    justify-content: center;
  }
  .lg-justify-between {
    justify-content: space-between;
  }
  .lg-justify-around {
    justify-content: space-around;
  }
  .lg-justify-evenly {
    justify-content: space-evenly;
  }
  .lg-justify-start {
    justify-content: flex-start;
  }
  .lg-justify-end {
    justify-content: flex-end;
  }
  .lg-w-auto {
    width: auto;
  }
  .lg-w-1-5 {
    width: 20%;
  }
  .lg-w-1-4 {
    width: 25%;
  }
  .lg-w-1-3 {
    width: 33.33333%;
  }
  .lg-w-3-8 {
    width: 36.5%;
    padding: 0 15px;
  }
  .lg-w-2-5 {
    width: 40%;
  }
  .lg-w-1-2 {
    width: 50%;
  }
  .lg-w-3-5 {
    width: 60%;
  }
  .lg-w-5-8 {
    width: 62.5%;
    padding: 0 15px;
  }
  .lg-w-2-3 {
    width: 66.66666%;
  }
  .lg-w-3-4 {
    width: 75%;
  }
  .lg-w-4-5 {
    width: 80%;
  }
  .lg-flex-row-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg-text-xs {
    font-size: 0.5rem;
  }
  .lg-text-sm {
    font-size: 0.75rem;
  }
  .lg-text-base {
    font-size: 1rem;
  }
  .lg-text-lg {
    font-size: 1.2rem;
  }
  .lg-text-xl {
    font-size: 1.4rem;
  }
  .lg-text-xl2 {
    font-size: 1.6rem;
  }
  .lg-text-2xl {
    font-size: 1.8rem;
  }
  .lg-border-right-grey {
    border-right: 1px solid #9490af;
  }
  .lg-border-left-grey {
    border-left: 1px solid #9490af;
  }
  .lg-br-1 {
    border-right: 1px solid #e0e0e0;
  }
  .lg-m-0 {
    margin: 0;
  }
  .lg-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .lg-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg-mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .lg-mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .lg-mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .lg-mx-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .lg-my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .lg-my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .lg-my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .lg-my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .lg-my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .lg-mt-0 {
    margin-top: 0 !important;
  }
  .lg-mt-10 {
    margin-top: 10px;
  }
  .lg-mt-15 {
    margin-top: 15px;
  }
  .lg-mt-20 {
    margin-top: 20px;
  }
  .lg-mt-30 {
    margin-top: 30px;
  }
  .lg-mt-40 {
    margin-top: 40px;
  }
  .lg-mt-50 {
    margin-top: 50px;
  }
  .lg-mt-70 {
    margin-top: 70px;
  }
  .lg-mb-0 {
    margin-bottom: 0;
  }
  .lg-mb-10 {
    margin-bottom: 10px;
  }
  .lg-mb-15 {
    margin-bottom: 15px;
  }
  .lg-mb-20 {
    margin-bottom: 20px;
  }
  .lg-mb-30 {
    margin-bottom: 30px;
  }
  .lg-mb-40 {
    margin-bottom: 40px;
  }
  .lg-mb-50 {
    margin-bottom: 50px;
  }
  .lg-ml-auto {
    margin-left: auto;
  }
  .lg-ml-0 {
    margin-left: 0;
  }
  .lg-ml-10 {
    margin-left: 10px;
  }
  .lg-ml-15 {
    margin-left: 15px;
  }
  .lg-ml-20 {
    margin-left: 20px;
  }
  .lg-ml-30 {
    margin-left: 30px;
  }
  .lg-ml-40 {
    margin-left: 40px;
  }
  .lg-mr-auto {
    margin-right: auto;
  }
  .lg-mr-0 {
    margin-right: 0;
  }
  .lg-mr-10 {
    margin-right: 10px;
  }
  .lg-mr-15 {
    margin-right: 15px;
  }
  .lg-mr-20 {
    margin-right: 20px;
  }
  .lg-mr-30 {
    margin-right: 30px;
  }
  .lg-mr-40 {
    margin-right: 40px;
  }
  .lg-p-30 {
    padding: 30px;
  }
  .lg-py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .lg-py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .lg-py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .lg-py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .lg-py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .lg-py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .lg-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .lg-px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .lg-px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .lg-px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .lg-px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .lg-px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .lg-px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .lg-pl-0 {
    padding-left: 0px;
  }
  .lg-pl-10 {
    padding-left: 10px;
  }
  .lg-pl-15 {
    padding-left: 15px;
  }
  .lg-pl-20 {
    padding-left: 20px;
  }
  .lg-pl-30 {
    padding-left: 30px;
  }
  .lg-pr-0 {
    padding-right: 0px;
  }
  .lg-pr-10 {
    padding-right: 10px;
  }
  .lg-pr-15 {
    padding-right: 15px;
  }
  .lg-pr-20 {
    padding-right: 20px;
  }
  .lg-pr-30 {
    padding-right: 30px;
  }
  .lg-pt-10 {
    padding-top: 10px;
  }
  .lg-pt-20 {
    padding-top: 20px;
  }
  .lg-pt-30 {
    padding-top: 30px;
  }
  .lg-pt-40 {
    padding-top: 40px;
  }
  .lg-pt-50 {
    padding-top: 50px;
  }
  .lg-pb-0 {
    padding-bottom: 0;
  }
  .lg-pb-10 {
    padding-bottom: 10px;
  }
  .lg-pb-20 {
    padding-bottom: 20px;
  }
  .lg-pb-30 {
    padding-bottom: 30px;
  }
  .lg-pb-40 {
    padding-bottom: 40px;
  }
  .lg-pb-50 {
    padding-bottom: 50px;
  }
}

@media (min-width: 1291px) {
  .md-flex-reverse {
    flex-direction: row-reverse;
  }
  .md-items-center {
    align-items: center;
  }
  .md-justify-center {
    justify-content: center;
  }
  .md-justify-between {
    justify-content: space-between;
  }
  .md-justify-around {
    justify-content: space-around;
  }
  .md-justify-evenly {
    justify-content: space-evenly;
  }
  .md-justify-start {
    justify-content: flex-start;
  }
  .md-justify-end {
    justify-content: flex-end;
  }
  .md-w-auto {
    width: auto;
  }
  .md-w-1-5 {
    width: 20%;
  }
  .md-w-1-4 {
    width: 25%;
  }
  .md-w-1-3 {
    width: 33.33333%;
  }
  .md-w-3-8 {
    width: 36.5%;
  }
  .md-w-2-5 {
    width: 40%;
  }
  .md-w-1-2 {
    width: 50%;
  }
  .md-w-3-5 {
    width: 60%;
  }
  .md-w-5-8 {
    width: 62.5%;
  }
  .md-w-2-3 {
    width: 66.66666%;
  }
  .md-w-3-4 {
    width: 75%;
  }
  .md-w-4-5 {
    width: 80%;
  }

  .md-text-xs {
    font-size: 0.5rem;
  }
  .md-text-sm {
    font-size: 0.75rem;
  }
  .md-text-base {
    font-size: 1rem;
  }
  .md-text-lg {
    font-size: 1.2rem;
  }
  .md-text-xl {
    font-size: 1.4rem;
  }
  .md-text-xl2 {
    font-size: 1.6rem;
  }
  .md-text-2xl {
    font-size: 1.8rem;
  }
  .md-border-right-grey {
    border-right: 1px solid #9490af;
  }
  .md-border-left-grey {
    border-left: 1px solid #9490af;
  }
  .md-br-1 {
    border-right: 1px solid #e0e0e0;
  }
  .md-m-0 {
    margin: 0;
  }
  .md-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .md-mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .md-mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .md-mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .md-mx-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .md-my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .md-my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .md-my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .md-my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .md-my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .md-mt-0 {
    margin-top: 0 !important;
  }
  .md-mt-10 {
    margin-top: 10px;
  }
  .md-mt-15 {
    margin-top: 15px;
  }
  .md-mt-20 {
    margin-top: 20px;
  }
  .md-mt-30 {
    margin-top: 30px;
  }
  .md-mt-40 {
    margin-top: 40px;
  }
  .md-mt-50 {
    margin-top: 50px;
  }
  .md-mt-70 {
    margin-top: 70px;
  }
  .md-mb-0 {
    margin-bottom: 0;
  }
  .md-mb-10 {
    margin-bottom: 10px;
  }
  .md-mb-15 {
    margin-bottom: 15px;
  }
  .md-mb-20 {
    margin-bottom: 20px;
  }
  .md-mb-30 {
    margin-bottom: 30px;
  }
  .md-mb-40 {
    margin-bottom: 40px;
  }
  .md-mb-50 {
    margin-bottom: 50px;
  }
  .md-ml-auto {
    margin-left: auto;
  }
  .md-ml-0 {
    margin-left: 0;
  }
  .md-ml-10 {
    margin-left: 10px;
  }
  .md-ml-15 {
    margin-left: 15px;
  }
  .md-ml-20 {
    margin-left: 20px;
  }
  .md-ml-30 {
    margin-left: 30px;
  }
  .md-ml-40 {
    margin-left: 40px;
  }
  .md-mr-auto {
    margin-right: auto;
  }
  .md-mr-0 {
    margin-right: 0;
  }
  .md-mr-10 {
    margin-right: 10px;
  }
  .md-mr-15 {
    margin-right: 15px;
  }
  .md-mr-20 {
    margin-right: 20px;
  }
  .md-mr-30 {
    margin-right: 30px;
  }
  .md-mr-40 {
    margin-right: 40px;
  }
  .md-p-30 {
    padding: 30px;
  }
  .md-py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .md-py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .md-py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .md-py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .md-py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .md-py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .md-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .md-px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .md-px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .md-px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .md-px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .md-px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .md-px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .md-pl-0 {
    padding-left: 0px;
  }
  .md-pl-10 {
    padding-left: 10px;
  }
  .md-pl-15 {
    padding-left: 15px;
  }
  .md-pl-20 {
    padding-left: 20px;
  }
  .md-pl-30 {
    padding-left: 30px;
  }
  .md-pl-60 {
    padding-left: 30px;
  }
  .md-pr-0 {
    padding-right: 0px;
  }
  .md-pr-10 {
    padding-right: 10px;
  }
  .md-pr-15 {
    padding-right: 15px;
  }
  .md-pr-20 {
    padding-right: 20px;
  }
  .md-pr-30 {
    padding-right: 30px;
  }
  .md-pt-10 {
    padding-top: 10px;
  }
  .md-pt-20 {
    padding-top: 20px;
  }
  .md-pt-30 {
    padding-top: 30px;
  }
  .md-pt-40 {
    padding-top: 40px;
  }
  .md-pt-50 {
    padding-top: 50px;
  }
  .md-pb-0 {
    padding-bottom: 0;
  }
  .md-pb-10 {
    padding-bottom: 10px;
  }
  .md-pb-20 {
    padding-bottom: 20px;
  }
  .md-pb-30 {
    padding-bottom: 30px;
  }
  .md-pb-40 {
    padding-bottom: 40px;
  }
  .md-pb-50 {
    padding-bottom: 50px;
  }
}
