.open-bids-form {
  .form-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    color: #2c2a2b;
  }

  .results-list {
    .row-item {
      display: flex;
      flex-wrap: wrap;
      background: #ffffff;
      border-top: 1px solid #edeef2;
      border-bottom: 1px solid #edeef2;
      box-sizing: border-box;
      font-weight: 400;

      -moz-user-select: none;
      user-select: none;

      .product {
        color: #2c2a2b;
        .left {
          max-width: 48px;
          max-height: 48px;
          margin-right: 10px;
        }
        .right {
          .detail {
            font-size: 13px;
            line-height: 18px;
            /* or 138% */

            text-transform: capitalize;
            color: #2c2a2b;
            margin-bottom: 4px;
          }
          .status {
            svg {
              margin-top: -1px;
            }
            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              padding-left: 4px;
            }
          }
        }
      }

      .other {
        padding-left: 0;
        padding-right: 0;

        .price {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;

          &-title {
            line-height: 16px;
            color: #979797;
          }

          &-value {
            font-weight: 700;
            line-height: 150%;
            color: #2c2a2b;
            text-transform: uppercase;
          }

          &-bid {
            margin-top: 14px;
            line-height: 16px;
            color: #1b76bd;
            text-decoration-line: underline;
            cursor: pointer;
          }
        }

        .more {
          font-size: 14px;
          display: flex;
          justify-content: right;

          &-detail {
            width: 94px;
            height: 50px;
            color: #1b76bd;

            background: #edeef2;
            border: 1px solid #edeef2;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
