.dropdown-menu-bar {
  width: 100%;
  background-color: #eaecef;
  position: relative;

  ul.item-list {
    z-index: 1;
    background: #ffffff;
    border: 1px solid #eaecef;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);

    position: absolute;
    right: 0px;
    width: 140px;
    padding-left: 0;

    display: none;
    &.visible {
      display: block;
    }
  }
  li {
    border: 1px solid #eaecef;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    text-transform: capitalize;

    color: #2c2a2b;

    cursor: pointer;
    -moz-user-select: none;
    user-select: none;

    list-style: none;
    height: 40px;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
