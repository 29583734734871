.notice-form {
  margin: auto;
  margin-top: 20px;
  max-width: 640px;

  .form-container {
    width: 100%;
  }

  .logo-header {
    height: 120px;
    background: #edeef2;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 160px;
      height: 21px;
    }
  }

  .content {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #2c2a2b;

    div {
      font-family: 'Arial';
    }

    div span {
      padding-top: 4px;
      color: #1b76bd;
      text-decoration-line: underline;
    }

    .product {
      display: flex;
      position: relative;
      &-image {
        display: flex;
        flex-direction: column;

        img {
          max-width: 100%;
          height: 180px;
        }
        margin-right: 25px;
      }

      &-detail {
        &-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #979797;
        }
        &-price {
          font-weight: 700;
          font-size: 24px;
          line-height: 150%;
          color: #2c2a2b;
        }
        &-label {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #2c2a2b;
        }

        &-time {
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
          text-transform: capitalize;
          text-align: center;
          margin-bottom: 50px;
        }

        &-content {
          padding: 14px 22px;
          background: #edeef2;
          margin-right: 10px;
          font-family: 'Goldenbook W01 Bold';
          font-size: 14px;
          line-height: 18px;
          color: #979797;
        }

        &-number {
          font-size: 30px;
          line-height: 20px;
          color: #1b76bd;
        }
      }
    }
    .detail {
      font-weight: 700;
      font-size: 14px;
      line-height: 28px;
      /* or 200% */

      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #2c2a2b;
      margin-bottom: 15px;
    }
  }

  .sub-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #2c2a2b;
    margin-top: 35px;
    margin-bottom: 30px;
    font-family: 'Arial';
  }

  .btn-confirm {
    width: 300px;
    height: 50px;
    background-color: #2c2a2b;

    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    margin-top: 50px;
    margin-bottom: 80px;

    a {
      color: white;
      text-decoration-line: none;
      font-family: 'Arial';
    }
  }

  .support-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 35px;
    margin-bottom: 20px;
    height: 75px;

    background: #edeef2;

    .question {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      font-family: 'Arial';

      a {
        padding-top: 4px;
        color: #1b76bd;
        text-decoration-line: underline;
      }
    }

    .logo svg {
      width: 112px;
      height: 14px;
    }
  }
}
