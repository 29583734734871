.previous-bids-form {
  .form-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    color: #2c2a2b;
  }

  .results-list { 
    .row-item {
      display: flex;
      flex-wrap: wrap;
      background: #ffffff;
      border: 1px solid #edeef2;
      box-sizing: border-box;
      margin-bottom: 14px;
      font-weight: 400;

      .product {
        color: #979797;
        .left {
          max-width: 48px;
          max-height: 48px;
          margin-right: 10px;
        }
        .right {
          .detail {
            font-size: 13px;
            line-height: 18px;
            /* or 138% */

            text-transform: capitalize;
            margin-bottom: 4px;
          }
          .status {
            svg {
              margin-top: -1px;
            }
            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              padding-left: 4px;
            }
          }
        }
      }

      .other {
        padding-left: 0;
        padding-right: 0;
        .bids {
          padding-left: 5px;
          padding-right: 5px;
          text-align: center;

          font-size: 14px;
          line-height: 16px;
          text-decoration-line: underline;
          color: #1b76bd;

          cursor: pointer;
          -moz-user-select: none;
          user-select: none;
        }

        .buyer {
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;

          &-title {
            font-size: 12px;
            line-height: 13px;

            color: #979797;
          }

          &-value {
            cursor: pointer;
            font-size: 14px;
            line-height: 210%;
            /* or 29px */
            color: #000000;
            text-decoration: underline;
          }
        }

        .price {
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;

          &-title {
            font-size: 12px;
            line-height: 13px;

            color: #979797;
          }

          &-value {
            font-size: 14px;
            line-height: 210%;
            /* or 29px */
            color: #000000;
          }
        }

        .more {
          text-align: center;
          min-width: 24px;

          cursor: pointer;
        }
      }
    }
  }
}
